
@import './colors.scss';

.list {
    max-width: 650px;
    margin: 0 auto;

    .hideShowButton {
        background-color: darken($blueColor, 6);
        border-radius: 3px;
        z-index: 1000;

        &:hover {
            background-color: darken($blueColor, 10);
        }
    }

    .top {
        display: flex;
        position: relative;
        flex-direction: column;
        width: calc(100% - 10px);
        margin: 2vh auto;
        background-color: darken($backgroundColor, 10);
        border-radius: 5px;
        padding: 5px;
        min-height: 30px;

        .search, form {
            display: none !important;
        }

        &.visible {
            .search, form {
                display: flex !important;
            }
        }

        input {
            width: 95%;
            max-width: 400px;
            margin: 0.5vh auto;
            padding: 8px 0;
        }

        .search {
            justify-content: center;
            margin: 1vh auto;
            max-width: 250px;
            width: 75%;

            input {
                margin: auto 10px 1vh auto;
                width: 100%;
            }

            h3 {
                color: $titleColor;
                margin: auto;
            }
            
            select {
                width: 120px;
                height: 34px;
            }

            @media screen and (max-width: 500px) {
                input {
                    width: 90%;
                    margin: auto auto 1vh auto;
                }

                select {
                    margin: 1vh auto;
                }
            }
        }

        .add {
            width: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .input {
                position: relative;
                width: 100%;
                max-width: 350px;
                margin: auto;
                
                input {
                    margin: auto;
                    width: 100%;
                    padding-left: 5px;
                }

                .suggestions {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    left: 0px;
                    width: calc(100% + 8px);
                    cursor: pointer;
                    z-index: 2000;
                    max-height: 200px;
                    overflow-y: auto;
                    background-color: darken($backgroundColor, 3);

                    p.nothing-found {
                        text-align: center;
                        font-weight: bold;
                        cursor: context-menu;
                    }

                    .add-product-button {
                        border: none;
                        padding: 1rem;
                        border-radius: 5px;
                        margin: 0 auto 0.5rem auto;
                        font-weight: bold;
                        color: $textColor;
                        background-color: $blueColor;

                        &:hover {
                            background-color: darken($blueColor, 5);
                        }
                    }

                    .suggestion {
                        &:nth-child(odd) {
                            background-color: darken($backgroundColor, 12);
        
                            &:hover {
                                background-color: darken($backgroundColor, 14);
                            }
                        }
        
                        &:nth-child(even) {
                            background-color: darken($backgroundColor, 4);
        
                            &:hover {
                                background-color: darken($backgroundColor, 6);
                            }
                        }

                        p {
                            margin: auto auto auto 3%;
                            padding: 6px 0;
                            font-size: 18px;
                        }
                    }
                }
            }

            @media screen and (max-width: 500px) {
                .input {
                    display: flex;
                    justify-content: center;
                    width: calc(100% - 50px);

                    .suggestions {
                        top: 44px;
                        left: -4px;
                        width: 100%;
                    }

                    input {
                       
                        margin: 1vh auto;
                    }
                }
            }
        }
    }

    .category {
        border-radius: 10px;
        width: 100%;
        margin: auto;
        padding-bottom: 1vh;
    
        h2 {
            color: $textColor;
            padding-top: 5px;
        }
    
        .product {
            display: flex;
            position: relative;
            justify-content: space-between;
            padding: 4px 0px;
            color: $textColor;
            font-weight: bold;
            width: 95%;
            margin: 0 auto;

            &:nth-of-type(odd) {
                filter: brightness(0.96);
            }
    
            &.checked {
                p.text { 
                    color: darken($textColor, 35);
                    text-decoration: line-through;
                }
            }

            p.text {
                margin-left: 1%;
                margin: 8px 1%;
                font-size: 21px;
                cursor: pointer;

                @media screen and (max-width: 450px) {
                    font-size: 19px;
                }
            }

            .count {
                display: flex;
                justify-content: space-between;
                width: 110px;
                padding: 4px 0;

                span {
                    margin: auto 0;
                    font-size: 20px;
                    width: 30px;
                    text-align: center;
                }

                .line {
                    position: absolute;
                    background-color: $textColor;
                    width: 50%;
                    height: 4px;
                    top: calc(50% - 2px);
                }

                .min, .max {
                    margin: 0;
                }

                .min {
                    position: relative;
                    width: 40px;
                    cursor: pointer;

                    .line {
                        left: 10px;
                    }
                }

                .plus {
                    position: relative;
                    width: 40px;
                    cursor: pointer;

                    .line {
                        &.one {
                            left: 10px;
                        }

                        &.two {
                            transform: rotate(90deg);
                            left: 10px;
                        }
                    }
                }
            }
        }
    }

    .bottom {
        position: relative;
        min-height: 30px;
        margin-top: 2vh;
        width: calc(100% - 10px);
        background-color: darken($backgroundColor, 10);
        border-radius: 5px;
        padding: 5px;

        .info, .buttons {
            display: none;       
        }

        &.visible {
            .info, .buttons {
                display: flex;   
            }
        }

        .info {
            width: 90%;
            flex-direction: column;
            margin: auto;

            img {
                width: 40px;
                height: 40px;
                padding: 4px;
                background-color: lighten($blueColor, 8);
                border-radius: 5px;
            }

            .text {
                display: flex;
                justify-content: space-between;
                margin-left: 10px;
                width: calc(100% - 10px - 48px - 30px - 12px);
            }

            @media screen and (max-width: 500px) {
                width: 100%;

                .text {
                    width: calc(100% - 10px - 48px - 10px);
                }   
            }

            .count, .price, .weight {
                display: flex;
                margin: 0.5vh 0;
            }
        }

        .buttons {
            width: 100%;

            button {
                width: 150px;
                height: 40px;
                margin: 1vh auto;
                cursor: pointer;
            }
        }
    }
}

