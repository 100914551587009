
@import './colors.scss';

.edit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: auto;

    input {
        width: 90%;
        margin: 1vh 0;
        padding: 8px 0;

        &.name {
            margin: 1vh auto;
        }

        &.price {
            width: 100px;
        }
    }

    .selects {
        display: flex;
        justify-content: center;
    }

    select {
        height: 38px;
        margin: 1vh 2%;

        &.category {
            margin: 1vh 0;
            width: 150px;
        }

        &.store {
            width: 150px;
        }
    }

    .quantity {
        display: flex;
        justify-content: center;

        input {
            width: 100px;
            margin: 1vh 0;
        }

        select {
            width: 150px;
        }
    }

    .bottom {
        display: flex;
        justify-content: center;

        input.price {
            margin: 1vh 0;
        }

        button {
            width: 100px;
            height: 37px;
            margin: auto 2%;
        }
    }
}