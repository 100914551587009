
@import './colors.scss';

.edit {
    position: relative;
    display: flex;

    .content {
        display: flex;
        
        .picker {
            display: flex;
        }
    }

    button {
        width: 100px;
        height: 40px;
        margin: auto;
    }
}

