
@import './colors.scss';

.history {
    width: 100%;
    max-width: 650px;
    margin: auto;

    h2 {
        margin: 1vh 0;
    }

    h3 {
        text-align: center;
        color: $titleColor;
        margin: 0 auto 2vh auto;
    }

    .history.entry {
        width: 95%;
        margin: auto;
        padding: 8px 0; 
        color: $blueColor;
        font-weight: bold;

        .top {
            display: flex;
            justify-content: space-between;
            width: 95%;
        }

        &.first {
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
        }

        &.last {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        &:nth-child(odd) {
            background-color: darken($backgroundColor, 10);
        }

        &:nth-child(even) {
            background-color: darken($backgroundColor, 4);
        }

        .expand {
            background-color: inherit;
            border: none;
            cursor: pointer;
            width: 30px;
            height: 30px;
            margin: auto 5px;
        }

        .info {
            width: 70%;
            flex-direction: column;
            margin: auto;

            @media screen and (max-width: 500px) {
                width: 95%;
            }

            img {
                width: 40px;
                height: 40px;
                padding: 4px;
                background-color: lighten($blueColor, 8);
                border-radius: 5px;
            }

            .text {
                display: flex;
                justify-content: space-between;
                margin-left: 10px;
                width: 100%;
            }

            .count, .price, .weight {
                display: flex;
                margin: 0.5vh 0;
            }
        }
    }
}