
@import 'colors.scss';

.categories, .stores {
    max-width: 500px;
    margin: auto;
    
    .search {
        display: flex;
        justify-content: center;
        margin: 2vh auto;
        max-width: 390px;

        input {
            width: 100%;
            height: 30px;
            padding: 2px 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0;

            &:first-child {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            &:last-child {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            &:nth-child(odd) {
                background-color: darken($backgroundColor, 2);

                &:hover {
                    background-color: darken($backgroundColor, 4);
                }
            }

            background-color: darken($backgroundColor, 7);

            &:hover {
                background-color: darken($backgroundColor, 9);
            }
        }
    }

    .category, .store {
        display: flex;
        justify-content: space-between;
        color: $darkTextColor;
        font-size: 18px;
        height: 40px;

        .info {
            display: flex;
            width: calc(100% - 70px);
            margin-left: 4px;

            .color {
                width: 28px;
                height: 28px;
                border: 2px solid $textColor;
                margin: auto 0;
                border-radius: 3px;
            }
        }

        p {
            margin: auto 2%;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            width: 65px;
            margin-right: 5px;

            .deleteButton, .editButton {
                width: 30px;
                height: 30px;
                margin: auto 0;
            }
        }
    }

    .add {
        display: flex;
        justify-content: center;
        max-width: 650px;
        margin: auto;

        input {
            width: 100%;
            margin-right: 10px;

            &.name {
                width: 100%;
                max-width: 300px;
            }
        }

        .addButton {
            margin-left: 10px;
        }
    }
}
