
@import 'colors.scss';

.products {
    .search {
        display: flex;
        justify-content: center;
        margin: 2vh auto;
        max-width: 500px;

        input {
            width: 100%;
            height: 30px;
            padding: 2px 0;
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            margin: 0;

            &:first-child {
                .product {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }

            &:last-child {
                .product {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            &:nth-child(odd) {
                .product {
                    background-color: darken($backgroundColor, 2);

                    &:hover {
                        background-color: darken($backgroundColor, 4);
                    }
                }
            }

            .product {
                background-color: darken($backgroundColor, 7);

                &:hover {
                    background-color: darken($backgroundColor, 9);
                }
            }
        }
    }

    .product {
        padding: 5px 10px;
        max-width: 600px;
        margin: auto;

        .top {
            display: flex;
            justify-content: space-between;
            width: 99%;
            margin-left: 1%;
            color: $titleColor;
            font-weight: bold;
            font-size: 18px;

            .left {
                display: flex;

                p {
                    margin: auto;
                }

                .expand {
                    border: none;
                    background-color: inherit;
                    margin: auto 5px auto auto;
                    transition: 1s ease-in-out;
                    cursor: pointer;
                }
            }

            .buttons {
                display: flex;
                justify-content: space-between;
                width: 90px;

                .deleteButton {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .bottom {
            display: flex;
            width: 80%;
            margin: auto;

            .left, .right {
                width: 50%;
            }

            .left .category .color, .right .store .color {
                width: 16px;
                height: 16px;
                border: 2px solid lighten($textColor, 5);
                border-radius: 3px;
                
            }

            .left .category {
                .color {
                    margin: auto 0px auto 15px;
                }

                p {
                    margin: auto auto auto 4px;
                }
            }

            .right {
                .price, .store {
                    justify-content: flex-end;

                    p {
                        margin: auto 15px auto auto;
                    }
                }

                .store {
                    .color {
                        margin: auto 15px auto 0px;
                    }

                    p {
                        margin: auto 4px auto auto;
                    }
                }
            }

            .weight, .price, .store, .category {
                display: flex;
                margin: 3px 0;

                img {
                    width: 32px;
                    height: 32px;
                    padding: 4px;
                    background-color: lighten($blueColor, 8);
                    border-radius: 5px;
                }

                p {
                    margin: auto auto auto 15px;
                }
            }

            @media screen and (max-width: 600px) {
                width: 100%;
            }

            @media screen and (max-width: 500px) {
                flex-direction: column;

                .left, .right {
                    width: 100%;
                }

                .right {
                    .price, .store {
                        flex-direction: row-reverse;
                    }

                    .store {
                        .color {
                            margin: auto 0px auto 15px;
                        }
        
                        p {
                            margin: auto auto auto 4px;
                        }
                    }

                    .price p {
                        margin: auto auto auto 15px;
                    }
                }
            }
        }
    }

    .add {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 300px;
        margin: auto;

        input {
            width: 150px;
            height: 30px;
            margin: 2% 0px;

            &.name {
                width: 300px;
            }

            &.quantity {
                width: 130px;
            }
        }

        select {
            margin: 2% 0;
            height: 36px;
            width: 140px;
        }

        .selects {
            display: flex;
            justify-content: center;

            select.category {
                margin-right: 5px !important;
            }

            select.store {
                margin-left: 5px !important;
            }
        }

        .quantity {
            display: flex;
            justify-content: center;

            input {
                margin-right: 5px;
            }

            select {
                margin-left: 5px;
            }
        }

        .bottom {
            display: flex;
            justify-content: center;

            .price {
                margin: auto 5px auto 0;
            }

            .addButton {
                margin: auto 0px auto 5px;

                .line {
                    background-color: $blueColor;
                }
            }
        }
    }
}

