
@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$mobileHeader: 1360px;

* {
    scrollbar-width: auto;
    scrollbar-color: darken($backgroundColor, 2) darken($backgroundColor, 14);   /* scroll thumb & track */

    &::-webkit-scrollbar {
        width: 12px;               /* width of the entire scrollbar */
    }
    
    &::-webkit-scrollbar-track {
        background: darken($backgroundColor, 32);       /* color of the tracking area */
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $blueColor;    /* color of the scroll thumb */
    }
}

body {
    background-color: $backgroundColor;
    font-family: 'Roboto', sans-serif;
}

h2 {
    color: $titleColor;
    text-align: center;
}

.error {
    text-align: center;
    color: $errorColor;
}

header {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: $blueColor;

    @media screen and (max-width: $mobileHeader) {
        flex-direction: column;
    }
    
    button {
        color: $textColor;
        text-decoration: none;
        font-size: 18px;
        background-color: inherit;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
            color: darken($textColor, 8);
        }
    }

    .left {
        display: flex;

        .logo {
            display: flex;
            justify-content: center;
            width: 370px;
            height: 70px;
            margin: 5px auto 5px 10px;
            cursor: pointer;
            background-image: url('../images/logo.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;

            @media screen and (max-width: 450px) {
                width: 264px;
                height: 50px;
                margin: 15px auto 15px auto; 
            }
        }

        .hamburger {
            display: none;
            position: relative;
            margin: auto 0;
            width: 30px;
            height: 30px;
            cursor: pointer;

            @media screen and (max-width: $mobileHeader) {
                display: block;
                margin-right: 10px;
            }

            &:hover {
                .line {
                    background-color: darken($textColor, 6);
                }
            }

            .line {
                position: absolute;
                background-color: $textColor;
                width: 30px;
                height: 4px;

                &.one {
                    top: calc(50% - 10px);
                }

                &.two {
                    top: calc(50% - 2px);
                }

                &.three {
                    top: calc(50% + 6px);
                }
            }
        }
    }

    .right {
        display: none;
        justify-content: space-between;
        width: 90%;
        margin: auto 5%;

        &.show {
            display: flex;
        }

        nav {
            display: flex;
            justify-content: center;
    
            a {
                margin: auto 5%;
                width: 100px;
                text-align: center;
            }
        }
    
        .account {
            display: flex;
            justify-content: center;
            text-align: center;
            padding-top: 1%;
    
            a {
                margin: auto 5px;
            }

            p {
                color: $textColor;
                margin: auto;
                cursor: pointer;

                &:hover {
                    color: darken($textColor, 6);
                }

                &.logout {
                    background-color: darken($blueColor, 2);
                    border-radius: 5px;
                    width: 100px;
                    padding: 5px 0;
                    margin-left: 15px;
                    cursor: pointer;

                    &:hover {
                        color: darken($textColor, 6);
                        background-color: darken($blueColor, 8);
                    }
                }

                @media screen and (max-width: $mobileHeader) {
                    margin: 8px auto;

                    &.logout {
                        margin: 5px auto !important;
                        width: 120px;
                    }
                }
            }
        }

        @media screen and (max-width: $mobileHeader) {
            flex-direction: column;
            width: 100%;
            margin: auto;

            nav, .account {
                flex-direction: column;
                background-color: lighten($blueColor, 4);
                z-index: 10000;

                button {
                    margin: 1vh auto;
                }
            }
        }
    }
}


body {
    margin: 0;
    padding: 0;

    .app {
        min-height: 100vh;
        width: 100%;
        margin: 0;
        padding: 0;
    
        .mainContent {
            padding: 120px 2% 100px 2%;
            min-height: calc(100vh - 120px - 100px - 30px);
        }
    }
}

button {
    cursor: pointer;
}

.picker {
    .sketch-picker {
        max-width: 400px !important;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2000;
    }
}

.colorPreview {
    display: flex;
    width: 80px;
    height: 36px;
    border: 2px solid $textColor;
    border-radius: 5px;
    margin: auto 5px;
    cursor: pointer;
    text-align: center;
    line-height: 14px;
    font-size: 13px;

    span {
        margin: auto;
        color: $darkTextColor;
    }

    &:hover {
        border-color: darken($textColor, 2);
    }
}

.addButton {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 5%;
    border-radius: 5px;
    cursor: pointer;

    &:hover .line {
        background-color: darken($textColor, 6);
    }

    .line {
        position: absolute;
        width: 80%;
        height: 5px;
        background-color: $textColor;
        top: calc(50% - 2.5px);
        left: 10%;

        &.two { transform: rotate(90deg); }
    }
}

.editButton {
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: auto 0;
    padding: 0;
    cursor: pointer;
    background-color: $textColor;
    border-radius: 5px;

    &:hover {
        background-color: darken($textColor, 4);

        svg {
            fill: darken($titleColor, 4);
        }
    }

    svg {
        width: 100%;
        height: auto;
        padding: 4px;
        fill: $titleColor;

        
    }
}

.deleteButton {
    position: relative;
    width: 30px;
    height: 30px;
    margin-right: 2%;
    background-color: $errorColor;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: darken($errorColor, 3);

        .line {
            background-color: darken($textColor, 5);
        }
    }

    .line {
        position: absolute;
        width: 80%;
        height: 4px;
        background-color: $textColor;
        top: calc(50% - 2px);
        left: 10%;

        &.one { transform: rotate(-45deg); }

        &.two { transform: rotate(45deg); }
    }
}

.hideShowButton {
    position: absolute;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    .line {
        position: absolute;
        height: 5px;
        background-color: $textColor;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        .line {
            background-color: darken($textColor, 4);
        }
    }

    .line {
        width: 70%;
        left: 5px;
        top: 12px;
    }

    &.visible {
        .line {
            width: 52%;

            &.one {
                left: 3px;
                top: calc(50% - 2px);
                transform: rotate(45deg);
    
    
            }
    
            &.two {
                left: 12px;
                top: calc(50% - 2px);
                transform: rotate(-45deg);
            }
        }
    }
}

.nav {
    display: flex;
    justify-content: center;
    margin: 4vh auto;
    width: 100%;

    .arrowLeft, .arrowRight {
        position: relative;
        width: 30px;
        height: 40px;
        cursor: pointer;

        .line {
            display: none;
            position: absolute;
            width: 28px;
            height: 5px;
            background-color: $titleColor;
        }

        &.show .line {
            display: block;
        }

        &:hover {
            .line {
                background-color: darken($titleColor, 10);
            }
        }
    }

    .arrowLeft {
        margin-right: 20px;

        .line.one {
            top: 26px;
            transform: rotate(45deg);

        }

        .line.two {
            top: 9px;
            transform: rotate(-45deg);
        }
    }

    .arrowRight {
        margin-left: 20px;

        .line.one {
            top: 26px;
            left: 5px;
            transform: rotate(-45deg);
        }

        .line.two {
            top: 9px;
            left: 5px;
            transform: rotate(45deg);
        }
    }

    .pageNumbers {
        display: flex;
        justify-content: center;

        .number {
            margin: auto 10px;
            font-weight: bold;
            font-size: 20px;
            color: $titleColor;
            cursor: pointer;

            &.current {
                text-decoration: underline;
            }

            &:hover {
                color: darken($titleColor, 10);
            }
        }
    }
}

.edit .buttons {
    display: flex;
    justify-content: center;

    button {
        margin: auto 1% !important;
    }
}

footer {
    display: flex;
    width: 100%;
    height: 30px;
    background-color: $blueColor;

    a { 
        margin: auto;
        color: $textColor;
        text-decoration: none;
        font-size: 20px;

        &:hover {
            color: darken($textColor, 12);
        }
    }
}
