
#rensAlert {
    .popup, .confirm, .accept, .input {
        display: flex;
        position: fixed;
        flex-direction: column;
        top: 20vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        text-align: center;
        background-color: #fffdfd;
        width: 95%;
        max-width: 650px;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
        z-index: 10000;
        border-radius: 5px;

        .top {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            button {
                background-color: #d30e0e;
                border: none;
                right: 5px;
                top: 5px;
                width: 25px;
                height: 25px;
                margin: 5px 5px 0 0;
                color: #FFF;
                cursor: pointer;
            }
        }

        .content {
            margin: 3% auto 5% auto; 
            width: 90%;

            input {
                margin-top: 5%;
                padding: 4px 0;
            }

            p { 
                margin: 4%;
                margin-bottom: 2%;
                width: 92%;
            }
        }

        .buttons {
            button {
                padding: 8px 10px;
                margin: 0.2vh 10px 1vh 10px;
                border-radius: 5px;
                color: #fffdfd;
                border: none;
                min-width: 50px;
            }

            .acceptButton {
                background-color: #4a8540;
            }

            .declineButton {
                background-color: #d30e0e;
            }
        }
    }
}