
@import './colors.scss';

.register, .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    margin: auto;

    h2 {
        text-align: center;
        color: $titleColor;
        margin: 2vh 0 0 0;
    }

    input {
        padding: 8px 0;
        margin: 1vh 0;

        &[type=submit] {
            border: none;
            background-color: $blueColor;
            color: $textColor;
            font-weight: bold;
            height: 35px;
            max-width: 300px;
            width: 100%;
            margin: 0 auto;
            cursor: pointer;

            &:hover {
                background-color: darken($blueColor, 4);
            }
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .error {
            text-align: center;
            color: $errorColor;
            margin: 0;
        }

        .controls {
            display: flex;
            justify-content: center;
            margin-top: 1vh;

            input {
                &[type=submit] {
                    max-width: 200px;
                }
    
                &[type=checkbox] {
                    margin: auto;
                }
            }
    
            label {
                margin: auto auto auto 0;
            }
        }
    }
}