
@import './colors.scss';

.edit.store {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin: auto;

    .content {
        display: flex;
    }

    input {
        width: 90%;
        margin: 1vh auto;
    }

    button {
        width: 100px;
        height: 40px;
        margin: 1vh auto;
    }
}